export const useSiteSeo = (site, route, options = {}) => {
  const tagOverrides = site.value.metatags.filter(
    (tag) => tag.key === route.path,
  )
  const titleTagOverride = tagOverrides.find((t) => t.name === "title")
  const descriptionTagOverride = tagOverrides?.find(
    (t) => t.name === "description",
  )

  if (titleTagOverride) {
    delete options.title
  }

  useHead({
    title: titleTagOverride?.content
      ? titleTagOverride?.content
      : options.title || site.value.seoDefaultTitle,
    titleTemplate:
      "%s %separator %siteDefaultTitle %separator %siteNameWithTld",
    templateParams: {
      siteGender: site.value.gender === "girls" ? "Girls" : "Guys",
      siteName: site.value.seoSiteName,
      siteNameWithTld: site.value.seoSiteNameWithTld,
      siteNameWithSpaces: site.value.seoSiteNameWithSpaces,
      siteNameWithoutSpaces: site.value.seoSiteNameWithoutSpaces,
      siteDefaultTitle: site.value.seoDefaultTitle,
      siteDefaultDescription: site.value.seoDefaultDescription,
    },
    meta: [
      {
        name: "description",
        content: descriptionTagOverride?.content
          ? descriptionTagOverride?.content
          : options.description || site.value.seoDefaultDescription,
      },
    ],
    ...options,
  })
}
